
import Component, { ComponentMethods } from "@/module/component/component"
import Message from "@/module/interactive/message"
import Request from "@/module/request/request"
import Utils from "@/module/utils/utils"
import Href from "@/module/config/href"

import elemFilter from "@/components/elem-filter.vue"
import elemIcon from "@/components/elem-icon.vue"
import elemOptions from "@/components/elem-options.vue"
import elemSwitch from "@/components/elem-switch.vue"
import compEntity from "@/components/comp-entity.vue"
import compMenu from "@/components/comp-menu.vue"
import { PageLoading } from "@/module/loading/loading"

class TableView extends ComponentMethods implements ComponentEntity {
    private pageName = "Table"

    /** 表格配置 */
    public tableConfig: obj = {
        pages: [],
    }

    private name: string = null

    // 是否开启时间检索功能
    public isOpenTimeRetrieval: boolean = false

    public moreMenu = [
        {
            title: "导出数据",
            prompt: "将以 Excel 表格格式导出数据文件",
            sub: [
                {
                    id: "ExportDataByPage",
                    icon: "page",
                    name: "当前页面",
                },
                {
                    id: "ExportAllData",
                    icon: "data",
                    name: "所有数据",
                },
            ],
        },
    ]

    private loading: PageLoading

    data = {
        table: null,
        limit: 10,
        page: 1,
        totalElements: 0,
        totalPages: 0,
        displayLimitOptions: false,
        displayPageOptions: false,
        search: "",

        // 操作菜单悬浮
        suspension: false,
    }

    watch = {
        limit() {
            this.getData()
        },
        page() {
            this.getData()
        },
    }

    components = {
        elemIcon,
        elemOptions,
        elemSwitch,
        compEntity,
        compMenu,
        elemFilter,
    }

    mounted() {
        this.loading = new PageLoading(this.$el)
    }

    async onLoad(param: obj) {
        this.loading && this.loading.show()

        const name = (this.name = this.getParam<string>("name"))

        const config = (this.pageConfig = await Href.getPage(name))

        if (config === null) {
            return this.jump("/error", { t: "404" })
        }

        this.setTitle(config.title)

        const res = await Request.get<obj>("ADMIN://" + config.tableDataUrl)

        this.api = res.api
        this.tableConfig = res
        this.isOpenTimeRetrieval = res.time

        for (let i = 0, o = res.operatings, l = o.length; i < l; i++) {
            if (o[i].type === "ENTITY") {
                this.$refs.comp_entity.setConfig({
                    title: config.title,
                    name: config.name,
                })
            }
        }

        // 判断是否存在参数
        if (Utils.isExist(param)) {
            let obj = Utils.copy(param)
            // 删除 name 的属性
            delete obj.name
            // 在判断是否存在，存在就写入条件
            if (Utils.isExist(obj)) this.filter = obj
            else this.filter = null
        } else this.filter = null

        // 获取数据
        this.getData()

        Utils.wait(() => {
            Utils.getElement("#BaseID", b => {
                this.suspension = b.clientWidth < b.scrollWidth
            })

            Utils.wait(() => {
                this.loading.hide()
            }, 500)
        })
    }

    /**
     * 监听窗口大小变化事件
     */
    onResize() {
        Utils.getElement("#BaseID", b => {
            this.suspension = b.clientWidth < b.scrollWidth
        })
    }

    getData() {
        Request.post<obj>(
            this.api + "/FindAllToPage",
            { page: this.page - 1, size: this.limit, search: this.search, filter: this.filters },
            {
                json: true,
            }
        ).then(res => {
            this.table = res.content
            this.totalElements = res.totalElements
            this.totalPages = res.totalPages
        })
    }

    onChangeFilter(evt: obj) {
        const _filter = evt.value

        if (this.filter && Object.keys(this.filter).length > 0) {
            Utils.each(Object.keys(this.filter), k => {
                _filter.push({
                    key: k,
                    value: this.filter[k],
                })
            })
        }

        this.filters = _filter

        this.getData()
    }

    onSelectLimit(evt: Event): void {
        const e = Utils.getTarget(evt)
        const value = Number(e.innerText)

        if (value !== this.limit) {
            this.limit = value
        }
    }

    onChangePages(type: "previous" | "next"): void {
        if (type === "previous") {
            this.page <= 1 ? Message.info("当前为第一页") : this.page--
        } else {
            this.page >= this.totalPages ? Message.info("当前为最后一页") : this.page++
        }
    }

    onSelectPages(evt: Event): void {
        const e = Utils.getTarget(evt)
        const value = Number(e.innerText)

        if (value !== this.page) {
            this.page = value
        }
    }

    onOpenSubclass(field, mappedBy, title, id) {
        const { i, data } = Utils.find<obj>(this.table, c => c.uuid === id)

        if (i > -1) {
            if (data[field] <= 0) {
                Message.info("当前字段无记录")
            } else {
                // Windows.open({
                //     type: "table",
                //     title,
                //     mappedBy,
                //     id
                // })
            }
        }
    }

    onDelete(id) {
        Message.info("确认删除该记录？", true)
            .onConfirm(() => {
                Request.delete(this.api + "/Delete?i=" + id).then(() => {
                    Message.success("删除成功", true)
                        .onTimeout(() => {
                            this.getData()
                        })
                        .build()
                })
            })
            .build()
    }

    onOpenCreate() {
        // Windows.open({ type: "create" })
    }

    onOpenEdit(id: string): void {
        this.jump("/edit", {
            url: this.api,
            i: id,
        })
    }

    /**
     * 监听跳转事件
     */
    onOperatingJump(item, url, msg) {
        this.onOperatingMsg(msg, () => {
            window.open(this.getUrl(item, url))
        })
    }

    /**
     * 监听请求事件
     */
    async onOperatingRequest(method, item, url, msg) {
        this.onOperatingMsg(msg, () => {
            Request.method(method, this.getUrl(item, url)).then(() => {
                Message.success("成功")
                // 刷新数据
                setTimeout(() => {
                    this.getData()
                }, 1000)
            })
        })
    }

    onOperatingMsg(msg, cb) {
        if (msg) {
            Message.info(msg.content, true)
                .setButton({
                    confirm: msg.confirm,
                    cancel: msg.cancel,
                })
                .onConfirm(cb)
                .build()
        } else cb()
    }

    /**
     * 文件上传操作事件
     */
    onOperatingFile(data, url, msg, config) {
        const input = document.createElement("input")

        input.type = "file"
        input.accept = config.accept

        input.onchange = (evt: any) => {
            var formData = new FormData()

            formData.append("file", evt.target.files[0])

            Request.post(this.getUrl(data, url), formData).then(() => {
                Message.success("上传成功", true)
                    .onTimeout(() => {
                        this.getData()
                    })
                    .build()
            })
        }

        input.click()
    }

    onOperatingPopup(data, url, msg) {
        this.onOperatingMsg(msg, () => {
            // Popup.open(this.getUrl(data, url))
        })
    }

    onChangeDataBySwitch(evt, field, id) {
        Request.post(
            this.api + "/ChangeFieldValue",
            { entity: id, field, value: evt.value },
            {
                json: true,
                onFail: () => {
                    evt.restore()
                },
            }
        )
    }

    /**
     * 解析 url
     */
    getUrl(data: obj, url: string): string {
        return new Function(`return \`${url.replace(/\*{(\w*)}/g, "${this.path.$1}").replace(/&{(\w*)}/g, "${this.data.$1}")}\``).call({
            path: {
                api: this.api,
                page: this.page,
            },
            data: data,
        })
    }

    openEntity(data: obj) {
        this.$refs.comp_entity.open(data.uuid)
    }

    onSelectMoreMenu(evt: obj) {
        switch (evt.value) {
            case "ExportAllData":
                Request.download(this.pageConfig.title + ".xlsx", "GET", this.api + "/ExportAllData")
                break
            case "ExportDataByPage":
                Request.download(
                    this.pageConfig.title + ".xlsx",
                    "POST",
                    this.api + "/ExportDataByPage",
                    { page: this.page - 1, size: this.limit, search: this.search, filter: this.getFilter() },
                    {
                        json: true,
                    }
                )
        }
    }

    /**
     * 跳转至表单页面
     */
    jumpForm(param: obj): void {
        if (this.pageConfig.formUrl) {
            this.jump("/link", {
                path: Utils.jsonToParams(param, this.pageConfig.formUrl),
            })
        } else {
            this.jump("/form", { ...param, ...this.getParams() })
        }
    }

    getConditionValue(where: string, value: obj) {
        if (!where) return true
        return new Function(`return ${where.replace(/&{(\w*)}/g, "this.$1")}`).call(value)
    }
}

export default Component.build(new TableView())
